import logo from './logo.svg';
import './App.css';
import React from 'react';
/* import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from 'react-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className='tabPanel'
      role="tabpanel"
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {(
        <Box sx={{ paddingTop: "1.25rem", paddingBottom: "1.25rem", paddingInline: "2rem"}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}



function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function ChangeButton(value, setValue) {

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

      return <button onClick={(e) => handleChange(e, 0)}>{value}</button>
    }

  return (
    <>
    <Box
      sx={{ flexGrow: 1, display: 'flex', position: "fixed", top: "35vh", maxWidth: "20rem", fontSize: '4rem'}}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider'}}
      >
        <Tab href="#vertical-tabpanel-0" label="Item One" {...a11yProps(0)} sx={{ color: 'red !important', fontSize: '2rem', fontWeight: 800 }}/>
        <Tab href="#vertical-tabpanel-1"label="Item Two" {...a11yProps(1)} sx={{ color: 'orange !important', fontSize: '1.80rem', fontWeight: 800  }}/>
        <Tab href="#vertical-tabpanel-2"label="Item Three" {...a11yProps(2)} sx={{ color: 'yellow !important', fontSize: '1.70rem', fontWeight: 800  }}/>
        <Tab href="#vertical-tabpanel-3"label="Item Four" {...a11yProps(3)} sx={{ color: 'green !important', fontSize: '1.60rem', fontWeight: 800  }}/>
        <Tab href="#vertical-tabpanel-4"label="Item Five" {...a11yProps(4)} sx={{ color: 'blue !important', fontSize: '1.50rem', fontWeight: 800  }}/>
        <Tab href="#vertical-tabpanel-5"label="Item Six" {...a11yProps(5)} sx={{ color: 'indigo !important', fontSize: '1.40rem', fontWeight: 800  }}/>
        <Tab href="#vertical-tabpanel-6"label="Item Seven" {...a11yProps(6)} sx={{ color: 'violet !important', fontSize: '1.30rem', fontWeight: 800  }}/>
      </Tabs>
      
    </Box>
    <div className='middle'>
    <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </div>
      
    {ChangeButton(value, setValue)}
    </>
    
  );
} */


function Toss(event) {
  let target = document.getElementById(event.target.attributes.id.nodeValue)
}

function App() {
  return (
    <div className="App">

    


      <div className='keep-it-rolling'>
        <div className='words-of-wisdom'>
          There'll be something here
        </div>
        
        <div className='ball-juggler'>
          <img id='c1' onClick={(e) => Toss(e)} src={logo} alt="" className='App-logo' />
          <img id='c2' onClick={(e) => Toss(e)} src={logo} alt="" className='App-logo' />
          <img id='c3' onClick={(e) => Toss(e)} src={logo} alt="" className='App-logo' />
        </div>


      </div>

    </div>
  );
}

export default App;
